import { Input as AInput, Col, Form, Row, FormInstance } from 'antd';
import Select from 'components/Select/Select';
import React, { FC, useEffect, useMemo, memo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import utilsMessages from 'utils/messages';
import { getDistrictByProvince, getWardsByDistrict, fetchProvince } from './action';
import reducer from './reducer';
import saga from './saga';
import selectAddressComponent from './selectors';
import { RootState } from './type';
import { District, Province, Ward } from '../../types/schema';
interface IDispatchProps {
  getDistrictData: (payload: { provinceId: string; cb: (data: District[]) => void }) => void;
  getWardData: (payload: { districtId: string; cb: (data: Ward[]) => void }) => void;
  getProvinceData: (payload: { regions?: string[]; cb: (data: Province[]) => void }) => void;
}

interface IStateProps {
  address: any;
}

interface IOwnProps {
  form: FormInstance<any>;
  fieldName: {
    city: string | string[];
    district: string | string[];
    ward: string | string[];
    addressDetail: string | string[];
  };
  fieldNameFullText?: {
    city?: string | string[];
    district?: string | string[];
    ward?: string | string[];
    addressDetail?: string | string[];
  };
  provinceValue?: string;
  districtValue?: string;
  wardValue?: string;
  addressDetailValue?: string;
  regions?: string[];
}

type Props = IDispatchProps & IOwnProps & IStateProps;

const AntdInput = styled(AInput)<any>`
  border-radius: 8px !important;
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 12px !important;
`;

const Address: FC<Props> = (props) => {
  const {
    // address,
    fieldNameFullText,
    fieldName,
    form,
    getWardData,
    getDistrictData,
    getProvinceData,
    provinceValue,
    districtValue,
    wardValue,
    addressDetailValue,
    regions,
  } = props;
  const currentLang = window.localStorage.getItem('lang') || 'vi';
  const [provinces, setProvinceData] = useState([] as Province[]);
  const [districts, setDistrict] = useState([] as District[]);
  const [wards, setWards] = useState([] as Ward[]);
  // const { provinces, districts, wards } = useMemo(() => {
  //   if (Object.keys(address).length && instanceId) {
  //     if (address[instanceId]) {
  //       return address[instanceId];
  //     }
  //   }
  //   return {
  //     provinces: [],
  //     districts: [],
  //     wards: [],
  //   };
  // }, [address, instanceId]);

  const provinceData = useMemo(() => {
    if (provinces?.length) {
      return provinces?.map((pv) => ({
        label: currentLang === 'vi' ? pv.fullName : pv.fullNameEn,
        value: pv.code,
      }));
    }
    return [];
  }, [provinces]);

  const districtsData = useMemo(() => {
    if (districts?.length) {
      return districts?.map((dis) => ({
        label: currentLang === 'vi' ? dis.fullName : dis.fullNameEn,
        value: dis.code,
      }));
    }
    return [];
  }, [districts]);

  const wardsData = useMemo(() => {
    if (wards?.length) {
      return wards?.map((ward) => ({
        label: currentLang === 'vi' ? ward.fullName : ward.fullNameEn,
        value: ward.code,
      }));
    }
    return [];
  }, [wards]);

  const onChangeProvince = (provinceId) => {
    if (provinceId) {
      getDistrictData({ provinceId: provinceId, cb: (data) => setDistrict(data) });
      form.setFieldValue(fieldName.district, null);
      form.setFieldValue(fieldName.ward, null);
      if (fieldNameFullText?.city) {
        const provinceInfo = provinces.find((province) => province.code === provinceId);
        form.setFieldValue(fieldNameFullText?.city, provinceInfo?.fullNameEn);
      }
    }
  };

  const onChangeDistrict = (districtId) => {
    if (districtId) {
      getWardData({ districtId: districtId, cb: (data) => setWards(data) });
      form.setFieldValue(fieldName.ward, null);
      if (fieldNameFullText?.district) {
        const districtInfo = districts.find((district) => district.code === districtId);
        form.setFieldValue(fieldNameFullText?.district, districtInfo?.fullNameEn);
      }
    }
  };

  const onChangeAddressDetail = (e) => {
    if (fieldNameFullText?.addressDetail) {
      form.setFieldValue(fieldNameFullText?.addressDetail, e?.target?.value);
    }
  };

  useEffect(() => {
    if (provinceValue) {
      getDistrictData({ provinceId: provinceValue, cb: (data) => setDistrict(data) });
      form.setFieldValue(fieldName.city, provinceValue);
      if (fieldNameFullText?.city) {
        form.setFieldValue(fieldNameFullText?.city, provinceValue);
      }
    }
    if (districtValue) {
      getWardData({ districtId: districtValue, cb: (data) => setWards(data) });
      form.setFieldValue(fieldName.district, districtValue);
      if (fieldNameFullText?.district) {
        form.setFieldValue(fieldNameFullText?.district, districtValue);
      }
    }
    if (wardValue) {
      form.setFieldValue(fieldName.ward, wardValue);
      if (fieldNameFullText?.ward) {
        form.setFieldValue(fieldNameFullText?.ward, wardValue);
      }
    }
    if (addressDetailValue) {
      form.setFieldValue(fieldName.addressDetail, addressDetailValue);
      if (fieldNameFullText?.addressDetail) {
        form.setFieldValue(fieldNameFullText?.addressDetail, addressDetailValue);
      }
    }
    getProvinceData({
      regions: regions,
      cb: (data) => {
        setProvinceData(data);
      },
    });
  }, [provinceValue, districtValue, wardValue, addressDetailValue]);
  return (
    <div>
      <FormattedMessage {...utilsMessages.province}>
        {(province: string) => (
          <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: province }}>
            {(fieldRequired: string) => (
              <FormattedMessage {...utilsMessages.provincePlaceHolder}>
                {(provincePlaceHolder) => (
                  <FormItem
                    name={fieldName.city}
                    colon={false}
                    required={true}
                    label={province}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: fieldRequired,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      items={provinceData}
                      placeholder={provincePlaceHolder}
                      onChange={onChangeProvince}
                    />
                  </FormItem>
                )}
              </FormattedMessage>
            )}
          </FormattedMessage>
        )}
      </FormattedMessage>
      <Row gutter={12}>
        <Col span={12}>
          <FormattedMessage {...utilsMessages.newDistrict}>
            {(newDistrict: string) => (
              <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: newDistrict }}>
                {(fieldRequired: string) => (
                  <FormattedMessage {...utilsMessages.districtPlaceHolder}>
                    {(districtPlaceHolder) => (
                      <FormItem
                        name={fieldName.district}
                        colon={false}
                        required={true}
                        label={newDistrict}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: fieldRequired,
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          items={districtsData}
                          placeholder={districtPlaceHolder}
                          onChange={onChangeDistrict}
                        />
                      </FormItem>
                    )}
                  </FormattedMessage>
                )}
              </FormattedMessage>
            )}
          </FormattedMessage>
        </Col>
        <Col span={12}>
          <FormattedMessage {...utilsMessages.newWard}>
            {(newWard: string) => (
              <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: newWard }}>
                {(fieldRequired: string) => (
                  <FormattedMessage {...utilsMessages.wardPlaceHolder}>
                    {(wardPlaceHolder) => (
                      <FormItem
                        name={fieldName.ward}
                        colon={false}
                        required={true}
                        label={newWard}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: fieldRequired,
                          },
                        ]}
                      >
                        <Select
                          items={wardsData}
                          placeholder={wardPlaceHolder}
                          onChange={(wardId) => {
                            if (fieldNameFullText?.ward) {
                              const wardInfo = wards.find((ward) => ward.code === wardId);
                              form.setFieldValue(fieldNameFullText?.ward, wardInfo?.fullNameEn);
                            }
                          }}
                          showSearch
                          optionFilterProp="children"
                        />
                      </FormItem>
                    )}
                  </FormattedMessage>
                )}
              </FormattedMessage>
            )}
          </FormattedMessage>
        </Col>
      </Row>
      <FormattedMessage {...utilsMessages.newAddress}>
        {(companyName: string) => (
          <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: companyName }}>
            {(fieldRequired: string) => (
              <FormattedMessage {...utilsMessages.addressPlaceHolder}>
                {(addressPlaceHolder) => (
                  <FormItem
                    name={fieldName.addressDetail}
                    colon={false}
                    required={true}
                    label={companyName}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: fieldRequired,
                      },
                    ]}
                  >
                    <AntdInput onChange={onChangeAddressDetail} placeholder={addressPlaceHolder} />
                  </FormItem>
                )}
              </FormattedMessage>
            )}
          </FormattedMessage>
        )}
      </FormattedMessage>
      {fieldNameFullText && (
        <div style={{ display: 'none' }}>
          {fieldNameFullText?.city && <FormItem name={fieldNameFullText.city} />}
          {fieldNameFullText?.district && <FormItem name={fieldNameFullText.district} />}
          {fieldNameFullText?.ward && <FormItem name={fieldNameFullText.ward} />}
          {fieldNameFullText?.addressDetail && <FormItem name={fieldNameFullText.addressDetail} />}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector<RootState, IStateProps>({
  address: selectAddressComponent(),
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  getProvinceData: (payload: { regions?: string[]; cb: (data: Province[]) => void }) =>
    dispatch(fetchProvince.request(payload as any)),
  getDistrictData: (payload: { provinceId: string; cb: (data: District[]) => void }) =>
    dispatch(getDistrictByProvince(payload)),
  getWardData: (payload: { districtId: string; cb: (data: Ward[]) => void }) => dispatch(getWardsByDistrict(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer<IOwnProps>({ key: 'addressComponent', reducer: reducer });

const withSaga = injectSaga<IOwnProps>({ key: 'addressComponent', saga: saga });

export default compose(memo, withReducer, withSaga, withConnect)(Address);
