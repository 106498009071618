import { ApolloQueryResult } from '@apollo/client';
import { message } from 'antd';
import { updateNoteToCard } from 'containers/MainLayout/actions';
import { call, select, put, fork, take } from 'redux-saga/effects';
import { TreatmentNames } from 'split/constants';
import { selectTreatmentValueByName } from 'split/selectors';
import translations from 'translations';
import isGoogleBot from 'utils/urls/isGoogleBot';
import {
  HomePagePopupUnion,
  ProductFilter,
  ProductSortField,
  QueryProductSearchArgs,
  RecommendationItem,
  RecommendationWidget,
  ShowcaseItem,
  ShowcaseWidget,
  SortDirection,
  WidgetUnion,
} from 'types/schema';
import {
  addFavorite,
  removeFavorite,
  setProductNote,
  getRecommendationItems,
  getProducts,
  getWidgetConfig,
  getHomepagePopup,
  getBrands,
} from 'utils/apollo';
import utilMessages from 'utils/messages';
import { homeProducts, setWidgets, homeFavorite, homeSetProductNote, sethomePagePopup } from './actions';
import ActionTypes from './constants';
import { selectLoading, selectWidgets } from './selectors';
import { selectBuyer } from 'containers/MainLayout/selectors';
import MainActionTypes from 'containers/MainLayout/constants';

function* getSectionData(section: ShowcaseItem) {
  const { sort, filter } = section;
  const filterConfig: ProductFilter = { ...filter };
  if (filter) {
    if (filterConfig.categoryName && section.id) {
      delete filterConfig.categoryName;
      filterConfig['categoryIds'] = [section.id];
    }
  }
  const params: QueryProductSearchArgs = {
    sort: sort.length ? sort : [{ field: ProductSortField.Name, order: SortDirection.Asc }],
    filter: filterConfig,
    pagination: { page: 0, size: 10 },
  };
  yield put(homeProducts.request({ [section.key]: { data: Array.from({ length: 6 }).fill(0), error: false } }));
  const response = yield call(getProducts, params);
  if (response.errors) {
    yield put(homeProducts.failure({ [section.key]: { data: [], error: true } }));
  } else {
    yield put(homeProducts.success({ [section.key]: { data: response.data, error: false } }));
  }
}

function* getBrandsData() {
  const topBrandsQA = [58, 57, 56, 55, 54, 53, 52, 51, 50, 49, 48, 47, 46, 45, 44, 43, 42, 41, 40, 39];
  const topBrandsPROD = [43, 158, 1, 101, 106, 31, 220, 10, 194, 144, 19, 36, 134, 40, 127, 72, 246, 3, 45, 4];

  const brandIds = topBrandsPROD;
  const brandResponse = yield call(getBrands, {
    brandIds: brandIds,
    pagination: { page: 0, size: 20 },
  });

  if (brandResponse.errors) {
    yield put(homeProducts.failure({ ['TRUSTED_BRANDS']: { data: [], error: true } }));
  } else {
    const arrangedBrands: any[] = [];
    brandIds.forEach((brandId) => {
      const brand = brandResponse?.data?.find((item) => Number(item.id) === brandId);
      if (brand) {
        arrangedBrands.push(brand);
      }
    });
    yield put(homeProducts.success({ ['TRUSTED_BRANDS']: { data: arrangedBrands, error: false } }));
  }
}

function* loadShowcaseItems(currentShowcaseItems: ShowcaseWidget[], widgetItems: WidgetUnion[]) {
  const loading = yield select(selectLoading());
  const showcaseWidgets = widgetItems.filter((i) => i.type === 'ShowcaseWidget') as unknown as ShowcaseWidget[];

  const newShowcaseConfig = showcaseWidgets.filter(
    (sw) =>
      !currentShowcaseItems.find((csw) => csw.widgetId === sw.widgetId) ||
      !Object.keys(loading).length ||
      loading[sw.widgetId],
  );

  // const showcaseSections = newShowcaseConfig.map((section: ShowcaseWidget) => {
  //   return {
  //     ...section.item,
  //     key: section.widgetId
  //   }
  // });

  for (const item of newShowcaseConfig) {
    const section = item.item;
    yield fork(getSectionData, section);
  }
}

function* getRecommendationSectionData(
  recommendationWidget: RecommendationWidget,
  widgets: WidgetUnion[],
  recommendationWidgetIndex: number,
) {
  const response = yield call(getRecommendationItems, { pagination: { page: 0, size: 10 } });
  if (response.errors) {
    yield put(homeProducts.failure({ [recommendationWidget?.item.key]: { data: [], error: true } }));
  } else {
    yield put(homeProducts.success({ [recommendationWidget?.item.key]: { data: response.data, error: false } }));
  }
  widgets.splice(recommendationWidgetIndex, 0, recommendationWidget);
  yield put(setWidgets(widgets));
}

function* loadWidgets() {
  const loggedIn = !!localStorage.getItem('token');
  let buyer = yield select(selectBuyer());

  if (loggedIn && !buyer) {
    yield take(MainActionTypes.FETCH_BUYER_SUCCESS);
    buyer = yield select(selectBuyer());
  }

  const regionLocal = localStorage.getItem('region');

  const response = yield call(getWidgetConfig, {
    regions: loggedIn ? [buyer?.region || 'HCM'] : [regionLocal || 'HCM'],
  });
  if (!(response as unknown as ApolloQueryResult<WidgetUnion[]>).errors) {
    const currentWidgets = yield select(selectWidgets());
    const showcaseWidgets: ShowcaseWidget[] = currentWidgets.filter((i: WidgetUnion) => i?.type === 'ShowcaseWidget');
    yield fork(loadShowcaseItems, showcaseWidgets, response);

    const widgets: WidgetUnion[] = [...response];

    const recommendationWidgetIndex = response.findIndex((w) => w.type === 'RecommendationWidget');
    const recommendationWidget = widgets[recommendationWidgetIndex] as RecommendationWidget;
    widgets.splice(recommendationWidgetIndex, 1);
    yield put(setWidgets(widgets));

    if (loggedIn && recommendationWidget) {
      yield fork(getRecommendationSectionData, recommendationWidget, widgets, recommendationWidgetIndex);
    }
  }
}

function* loadHomepagePopUp() {
  const response = yield call(getHomepagePopup);
  if (!(response as unknown as ApolloQueryResult<HomePagePopupUnion>).errors) {
    if (response.key) {
      yield put(sethomePagePopup(response));
    } else {
      yield put(sethomePagePopup(undefined));
      window.localStorage.removeItem('blockHomePagePopup');
    }
  }
}

function* homeFavoriteFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.HOME_FAVORITE);
    yield put(homeFavorite.request());

    const {
      product: { isFavorite, id },
      index,
      section,
    } = payload;

    const response = yield call(isFavorite ? removeFavorite : addFavorite, { id: id });
    if (response.errors) {
      yield put(homeFavorite.failure({ index: index, section: section }));
    } else {
      yield put(homeFavorite.success({ index: index, section: section, isFavorite: !isFavorite }));
    }
  }
}

function* homeSetProductNoteFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.HOME_SET_PRODUCT_NOTE);
    yield put(homeSetProductNote.request());

    const { id, note, index, section } = payload;
    const response = yield call(setProductNote, { input: { productId: id, note: note } });
    if (response.errors) {
      yield put(homeSetProductNote.failure(response.errors));
      message.error(translations(utilMessages[payload.note ? 'addItemNoteFailed' : 'deleteItemNoteFailed']));
    } else {
      yield put(homeSetProductNote.success({ note: note, index: index, section: section }));
      yield put(updateNoteToCard(response.product));
      message.success(translations(utilMessages[payload.note ? 'addItemNoteSuccess' : 'deleteItemNoteSuccess']));
    }
  }
}

export default function* saga() {
  const googleBot = yield call(isGoogleBot);
  if (!googleBot) {
    const homepagePopupTreatment = yield select(selectTreatmentValueByName(TreatmentNames.BUYER_WEB_HOME_PAGE_POPUP));
    if (homepagePopupTreatment) {
      yield call(loadHomepagePopUp);
    }
  }

  yield call(loadWidgets);
  yield fork(getBrandsData);
  yield fork(homeFavoriteFlow);
  yield fork(homeSetProductNoteFlow);
}
