import React, { memo, useCallback, useMemo, useState } from 'react';
import CarouselBanner from '../CarouselBanner';
import match from 'utils/match';
import styled from 'styles/styled-components';
import Button from 'components/Button/Button';
import {
  Buyer,
  CarouselBannerWidget,
  RecommendationWidget,
  ShowcaseWidget as ShowcaseWidgetType,
  ShowPopupBannerItem,
  ShowWebViewBannerItem,
  WidgetUnion,
} from 'types/schema';
import HomeScreenWidget from 'components/HomeScreenWidget';
import CategoryNav from '../../containers/CategoryNav';
import { Viewport } from 'components/ViewportProvider';
import BrandWidget from 'components/BrandWidget';
import TagsNavBar from 'components/TagsNavBar';
import { Skeleton, Card, Col, Row, Space, Modal } from 'antd';
import media from 'utils/mediaStyle';

const { Meta } = Card;
interface IProps {
  widgets: WidgetUnion[];
  buyer?: Buyer;
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
`;

const PopUpImageContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 42%;
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;

const PopupContainer = styled(Modal)`
  min-width: 320px;

  .ant-modal-body {
    padding: 0px;
  }
`;

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

const IframeContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
`;

const ContentContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const HTMLContent = styled.div`
  font-size: 16px;
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
  img {
    width: 100%;
  }
  ul,
  ol {
    padding-left: 16px;
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
  }

  li {
    display: flex;

    ::before {
      content: '\\2022';
      color: rgba(0, 0, 0, 0.87);
      font-weight: bold;
      display: inline-block;
      margin-right: 0.5em;
      margin-left: -1em;
    }
  }
`;

const HeadingContainer = styled.div`
  font-size: 24px;
  padding: 24px;
`;

const SkeletonInputWrapper = styled.div`
  width: 100%;
  .ant-skeleton.ant-skeleton-element {
    width: 100%;
  }
  .ant-skeleton-input {
    min-width: 0 !important;
  }
`;

const SpaceContainer = styled.div`
  .ant-space-vertical {
    width: 100%;
  }
  padding: 0px 16px;
  ${media.md`
    padding: 0;
  `}
`;

const SpaceBanner = styled(Col)`
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

const EcommerceSkeleton = () => {
  return (
    <SpaceContainer>
      <Space direction="vertical" size="large">
        <Row style={{ width: '100%', marginLeft: '0', marginRight: '0' }} gutter={[30, 0]}>
          <Col xs={0} lg={5} style={{ paddingLeft: '0' }}>
            {[...Array(10)].map((_, idx) => (
              <SkeletonInputWrapper key={`Main_Content_Skeleton_${idx}`}>
                <Skeleton.Input active style={{ width: '100%', height: '30px', marginBottom: '5px' }} />
              </SkeletonInputWrapper>
            ))}
          </Col>

          {/*  */}
          <SpaceBanner
            xs={24}
            lg={19}
            style={{
              marginTop: '10px',
            }}
          >
            <Space direction="vertical" size="small">
              <Row gutter={[10, 0]}>
                {[...Array(4)].map((_, idx) => (
                  <Col span={6} key={`Banner_Skeleton_${idx}`}>
                    <SkeletonInputWrapper>
                      <Skeleton.Input active style={{ width: '100%', height: '40px' }} />
                    </SkeletonInputWrapper>
                  </Col>
                ))}
              </Row>

              <SkeletonInputWrapper>
                <Skeleton.Input style={{ width: '100%', height: '287px' }} active />
              </SkeletonInputWrapper>
            </Space>

            {/* Banner Skeleton */}
          </SpaceBanner>
        </Row>

        {/* Cards Skeleton */}
        <Row gutter={[16, 16]}>
          {[...Array(6)].map((_, idx) => (
            <Col key={`Cards_Skeleton_${idx}`} xs={24} sm={12} md={6} lg={4}>
              <Card style={{ width: '100%' }} cover={<Skeleton.Image style={{ width: '100%', height: '150px' }} />}>
                <Meta
                  title={<Skeleton.Input active style={{ width: '80%', height: '20px' }} />}
                  description={<Skeleton.Input active style={{ width: '60%', height: '20px' }} />}
                />
              </Card>
            </Col>
          ))}
        </Row>
      </Space>
    </SpaceContainer>
  );
};

const Widgets = (props: IProps) => {
  const { widgets, buyer } = props;

  const isLogin = window.localStorage.getItem('token');
  const regionLocal = localStorage.getItem('region');

  const [isShowModal, setIsShowModal] = useState(false);
  const [modalWidgetData, setModalData] = useState<ShowPopupBannerItem | ShowWebViewBannerItem>();

  const onClose = useCallback(() => {
    setModalData(undefined);
    setIsShowModal(false);
  }, []);

  const onOpenModal = useCallback((modalData) => {
    setModalData(modalData);
    setIsShowModal(true);
  }, []);
  const renderWidgetItem = useCallback(
    (widget) => {
      return match(widget, {
        CarouselBannerWidget: (carouselBannerwidget: CarouselBannerWidget) => (
          <CarouselBanner
            key={carouselBannerwidget.widgetId}
            banners={carouselBannerwidget.banner.items}
            onOpenPopup={onOpenModal}
          />
        ),
        RecommendationWidget: (recommendationWidget: RecommendationWidget) => {
          return (
            <HomeScreenWidget
              key={recommendationWidget.widgetId}
              showcaseId={recommendationWidget.widgetId}
              showcase={recommendationWidget.item}
            />
          );
        },
        ShowcaseWidget: (showcaseWidgetType: ShowcaseWidgetType) => {
          return (
            <HomeScreenWidget
              key={showcaseWidgetType.widgetId}
              showcaseId={showcaseWidgetType.widgetId}
              showcase={showcaseWidgetType.item}
            />
          );
        },
        CategoryMobile: (categoryMobile: { type: string; key: string }) => {
          return (
            <Viewport.Consumer>
              {({ width }) =>
                width < 1025 && (
                  <>
                    <CategoryNav key={categoryMobile.key} />
                    <TagsNavBar />
                  </>
                )
              }
            </Viewport.Consumer>
          );
        },
        TrustedBrands: () => {
          return <BrandWidget />;
        },
      });
    },
    [onOpenModal],
  );

  const content = useMemo(() => {
    const categoryMobile = {
      type: 'CategoryMobile',
      key: 'CATEGORY_MOBILE',
    };
    const trustedBrands = {
      type: 'TrustedBrands',
      key: 'TRUSTED_BRANDS',
    };
    if (!widgets.length) {
      return [];
    }

    const newWidgets = JSON.parse(JSON.stringify(widgets));
    newWidgets.splice(1, 0, categoryMobile);
    newWidgets.push(trustedBrands);
    return newWidgets
      ?.filter((widget) =>
        (isLogin && buyer?.region === 'HCM') || (!isLogin && regionLocal === 'HCM')
          ? true
          : widget.widgetId !== 'GYOMU_SUPER',
      )
      .map(renderWidgetItem);
  }, [widgets, widgets.length, renderWidgetItem, isLogin, buyer, regionLocal]);

  return (
    <Container>
      {content.length ? content : <EcommerceSkeleton />}
      {modalWidgetData &&
        match(modalWidgetData, {
          ShowPopupBannerItem: (popupItem: ShowPopupBannerItem) => (
            <PopupContainer open={isShowModal} onCancel={onClose} footer={null}>
              {!!popupItem.popupImageUrl && (
                <PopUpImageContainer>
                  <img src={popupItem.popupImageUrl} />
                </PopUpImageContainer>
              )}
              <ContentContainer>
                <HTMLContent dangerouslySetInnerHTML={{ __html: popupItem.popupContent }} />
                <Button type="secondary" block onClick={onClose}>
                  {popupItem.buttonText}
                </Button>
              </ContentContainer>
            </PopupContainer>
          ),
          ShowWebViewBannerItem: (iframeItem: ShowWebViewBannerItem) => (
            <PopupContainer open={isShowModal} onCancel={onClose} footer={null} width={'88.5%'}>
              <HeadingContainer>
                <h3>{iframeItem.title}</h3>
              </HeadingContainer>
              <IframeContainer>
                <Iframe
                  src={iframeItem.url}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
              </IframeContainer>
            </PopupContainer>
          ),
        })}
    </Container>
  );
};

export default Widgets;
